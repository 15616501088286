bcl.c.tabs = {
  init: function () {
    document.querySelectorAll(".cmp-tab-JS").forEach(function (component) {
      const selector = component.dataset.selectedTab;
      if (selector) {
        Array.from(component.querySelectorAll(".cmp-anchor-id-JS")).every(function (anchorElement) {
          if (anchorElement.dataset.anchorId === selector) {
            let selectedTab = bcl.u.closest(anchorElement, ".cmp-tab-panel-JS");
            if (selectedTab) {
              selectedTab = component.querySelector("li#tab-" + selectedTab.dataset.title);
            }
            if (selectedTab) {
              selectedTab.click();
            }
          } else {
            return true;
          }
        });
      }
    });
  },
};
bcl.u.docReady(bcl.c.tabs.init);
