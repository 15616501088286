bcl.u.utag = {
  props: {
    loading: 0,
    loadingAttempts: 0,
    attempts: 0,
    init: false,
    topush: false,
    lastready: false,
    pendding: false,
    queue: [],
    enabledListener: [],
  },

  isEnabled: function () {
    try {
      if (typeof utag_data !== "undefined") {
        return true;
      }
    } catch (e) {
      // DO NOTHING
    }

    return false;
  },

  isEnabledUtag: function () {
    try {
      if (typeof utag !== "undefined") {
        return true;
      }
    } catch (e) {
      // DO NOTHING
    }

    return false;
  },

  enableListener: function () {
    if (bcl.u.utag.isEnabled()) {
      bcl.u.utag.props.loading++;
      bcl.u.utag.props.topush = true;
    }
  },

  removeListener: function () {
    if (bcl.u.utag.isEnabled()) {
      document.dispatchEvent(new Event("utag-ready"));
    }
  },

  add: function (obj) {
    if (bcl.u.utag.isEnabled() && obj) {
      for (const prop in obj) {
        if (obj[prop] && (!Array.isArray(obj[prop]) || (Array.isArray(obj[prop]) && obj[prop].length))) {
          utag_data[prop] = obj[prop];
        }
      }
    }
  },

  init: function () {
    if (bcl.u.utag.props.pendding) {
      return;
    }

    if ((bcl.u.utag.props.loading <= 0 || bcl.u.utag.props.loadingAttempts >= 5) && bcl.u.utag.props.lastReady && bcl.u.utag.props.topush && bcl.u.utag.isEnabledUtag() && bcl.u.utag.isEnabled()) {
      bcl.u.utag.props.topush = false;
      utag_data["tealium_event"] = "view";
      utag_data["ut.event"] = "view";
      utag.view(utag_data);

      bcl.u.utag.props.init = true;

      for (let i = 0; i < bcl.u.utag.props.queue.length; i++) {
        bcl.u.utag.push(bcl.u.utag.props.queue[i]);
      }

      bcl.u.utag.props.queue = [];
    }

    if (bcl.u.utag.props.lastReady && !bcl.u.utag.props.init) {
      // Timeout event
      bcl.u.utag.props.pendding = true;
      bcl.u.utag.props.loadingAttempts++;

      setTimeout(function () {
        bcl.u.utag.props.pendding = false;
        bcl.u.utag.init(true);
      }, 2000);
    }
  },

  push: function (obj) {
    if (bcl.u.utag.isEnabled() && obj) {
      if (bcl.u.utag.props.init) {
        try {
          bcl.u.utag.sendData(obj);
        } catch (e) {
          console.error("Data push error", obj);
          // Do nothing
        }
      } else {
        bcl.u.utag.props.queue.push(obj);
      }
    }
  },

  sendData: function (obj) {
    try {
      if (bcl.u.utag.isEnabledUtag()) {
        bcl.u.utag.props.attempts = 0;
        utag.link(obj);
      }
    } catch (e) {
      bcl.u.utag.props.attempts++;
      if (bcl.u.utag.props.attempts <= 10) {
        setTimeout(function () {
          bcl.u.utag.sendData(obj);
        }, 200);
      }
    }
  },

  loadDataCookies: function () {
    const data = bcl.cookie.get("barcelo_search_uatg_data");

    if (!data) {
      return;
    }

    bcl.u.utag.add(JSON.parse(data));

    bcl.cookie.remove("barcelo_search_uatg_data", "/");
  },
};

bcl.u.docReady(bcl.u.utag.loadDataCookies, true);

document.addEventListener(
  "utag-ready",
  function () {
    bcl.u.utag.props.loading--;
  },
  false,
);
