import { BarceloComponent } from "barcelo-components";

// Workaround to fix import from component index returning an undefined when import containers from index
const AllContainers = {
  container: BarceloComponent({
    selector: [".c-mask", ".generic-container.mod--separator"],
    path: "containers/generic-container/js/c-container",
  }),
  swiper: BarceloComponent({ selector: ".c-swiper", path: "containers/swiper/js/c-swiper" }),
  accordion: BarceloComponent({ selector: ".accordion-item", path: "containers/accordion-item/js/c-accordion" }),
  tabsdropdown: BarceloComponent({ selector: ".c-tabsdropdown", path: "containers/dropdown/js/c-dropdown" }),
  tabMenu: BarceloComponent({ selector: ".c-tab-menu", path: "containers/tab-menu/tab-menu/js/c-tab-menu" }),
};

export default AllContainers;
