import datePicker from "barcelo-shared/datePicker";
import swiperMobile from "barcelo-shared/swiperMobile";
import currency from "barcelo-shared/currency";

const shared = {
  datePicker,
  swiperMobile,
  currency,
};

const isAlreadyInitialized = (module) => Object.keys(bcl.s).includes(module);

const BarceloShared = {
  init: () => {
    Object.entries(shared).forEach(([name, module]) => {
      if (!isAlreadyInitialized(name)) {
        module.init();
        if (!isAlreadyInitialized(module)) {
          bcl.s = {
            ...bcl.s,
            [name]: module,
          };
        }
      }
    });
  },
};

export { BarceloShared };
