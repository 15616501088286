bcl.c.accordion = {
  init: function () {
    document.querySelectorAll(".accordion-item").forEach(function (component) {
      const selector = (component.querySelector("div[data-selected-accordion]") || { dataset: {} }).dataset.selectedAccordion;
      if (selector) {
        Array.from(component.querySelectorAll(".cmp-anchor-id-JS")).every(function (anchorElement) {
          if (anchorElement.dataset.anchorId === selector) {
            let accordionToggle = bcl.u.closest(anchorElement, ".accordion-item");
            if (accordionToggle) {
              accordionToggle = accordionToggle.querySelector(".accordion-toggle-JS");
            }
            if (accordionToggle) {
              accordionToggle.click();
            }
          } else {
            return true;
          }
        });
      }
    });
  },
};
bcl.u.docReady(bcl.c.accordion.init);
