bcl.s.translateOnTheFly = {
  props: {
    whiteList: ["title", "placeholder", "data-months", "data-i18n-night", "data-i18n-nights", "data-weekdays", "data-desktop-placeholder"],
    rtlList: ["he-IL"],
    index: 0,
    fbi18n: "-fb-",
    excludeClasses: ["datepicker__month-day", "c-price__value-JS", "datepicker__week-name"],
    attributeSeparator: "-atb-",
    sourceLocaleId: "",
    targetLocaleId: "",
    sessionStoragePropName: "translated-pages",
    currentURL: window.location.href,
    dataAttributeTranslation: "data-translation-enabled-language",
    loaderSelector: "JS-translation-loader",
    treeWalkerRoot: document.body,
    fastBookingSelector: "fastbooking-JS",
    maxPerRequest: 3000,
    sessionStorageEnabledLanguageProp: "translation-enabled-language",
    sessionStorageOriginalLanguageProp: "original-page-language",
    parentLoader: null,
  },
  init: function () {
    bcl.s.translateOnTheFly.setSessionStorageKeys();
  },
  initTranslate: function (sourceLocaleId, targetLocaleId, $node) {
    if (bcl.editMode) {
      return;
    }
    bcl.c.header?.language?.updateSelectedTranslation();
    bcl.c.translationEnabledLanguageDialog?.closeAll();
    bcl.s.translateOnTheFly.initProps(sourceLocaleId, targetLocaleId, $node);
    let jsonEndpoint = {
      sourceLocaleId: bcl.s.translateOnTheFly.props.sourceLocaleId,
      targetLocaleId: bcl.s.translateOnTheFly.props.targetLocaleId,
      items: [],
    };
    let arrayText;
    let arrayAttributes;
    if ($node && ($node instanceof Array || $node instanceof NodeList) && $node.length) {
      const arraysObj = bcl.s.translateOnTheFly.loadArraysByNode($node);
      arrayText = arraysObj.arrayText;
      arrayAttributes = arraysObj.arrayAttributes;
      const parentNode = bcl.u.findLowestCommonAncestor($node);
      $node = parentNode && !parentNode?.classList.contains("result__list-JS") ? parentNode : document.body;
    } else {
      arrayText = bcl.s.translateOnTheFly.treeWalkerText();
      arrayAttributes = bcl.s.translateOnTheFly.treeWalkerAttributes();
    }
    const domNodesObj = { ...(arrayText || {}), ...(arrayAttributes || {}) };
    jsonEndpoint = bcl.s.translateOnTheFly.setItemsJsonArray(jsonEndpoint, $node, domNodesObj);
    bcl.s.translateOnTheFly.props.index = 0;
    bcl.s.translateOnTheFly.loadLoader();
    const items = jsonEndpoint.items;
    if (items) {
      const data = bcl.s.translateOnTheFly.getSessionStorageProp(items);
      if ($node || !data) {
        bcl.s.translateOnTheFly.translateThisText(jsonEndpoint, $node, domNodesObj);
        return;
      }
      bcl.s.translateOnTheFly.showLoader($node);
      bcl.s.translateOnTheFly.changeText(data.json, domNodesObj);
    }
    bcl.s.translateOnTheFly.hideLoader($node);
  },
  initProps: function (sourceLocaleId, targetLocaleId, $node) {
    bcl.s.translateOnTheFly.props.sourceLocaleId = sourceLocaleId;
    bcl.s.translateOnTheFly.props.targetLocaleId = targetLocaleId;
    bcl.s.translateOnTheFly.props.treeWalkerRoot = $node || document.body;
  },
  loadArraysByNode: function ($node) {
    const obj = { arrayText: [], arrayAttributes: [] };
    $node.forEach(function ($n) {
      if ($n instanceof Node) {
        bcl.s.translateOnTheFly.props.treeWalkerRoot = $n;
        obj.arrayText = { ...(obj.arrayText || []), ...(bcl.s.translateOnTheFly.treeWalkerText() || []) };
        obj.arrayAttributes = { ...(obj.arrayAttributes || []), ...(bcl.s.translateOnTheFly.treeWalkerAttributes() || []) };
      }
    });
    return obj;
  },
  loadLoader: function () {
    const parentLoader = document.querySelector("[" + bcl.s.translateOnTheFly.props.dataAttributeTranslation + "=" + bcl.s.translateOnTheFly.props.targetLocaleId + "]");
    if (!parentLoader) {
      return;
    }
    const loader = parentLoader.querySelector("." + bcl.s.translateOnTheFly.props.loaderSelector);
    if (loader) {
      bcl.s.translateOnTheFly.props.parentLoader = parentLoader;
    }
  },
  showLoader: function ($node) {
    if (bcl.s.translateOnTheFly.props.parentLoader && !$node) {
      bcl.s.translateOnTheFly.props.parentLoader.classList.remove("hidden");
      return;
    }
    $node = $node || document.body;
    if (($node.nodeName === "BODY" && !document.querySelector("body > div > .bhg-loading:not(.JS-translation-loader)")) || !$node.querySelector(".bhg-loading:not(.JS-translation-loader)")) {
      bcl.u.addBhgLoading($node, true, $node.nodeName === "BODY");
    }
  },
  hideLoader: function ($node) {
    bcl.s.translateOnTheFly.props.parentLoader && !$node ? bcl.s.translateOnTheFly.props.parentLoader.classList.add("hidden") : bcl.u.removeBhgLoading($node);
  },
  shouldEscape: function (node) {
    return (node.nodeType === Node.TEXT_NODE ? node.parentElement : node).closest(`[${bcl.s.translateOnTheFly.props.dataAttributeTranslation}]`);
  },
  treeWalkerText: function () {
    const json = {};
    const treeWalker = document.createTreeWalker(bcl.s.translateOnTheFly.props.treeWalkerRoot, NodeFilter.SHOW_TEXT, function (node) {
      const nodeIsValid =
        node.data.trim() &&
        node.parentNode.nodeName !== "SCRIPT" &&
        node.parentNode.nodeName !== "STYLE" &&
        !bcl.s.translateOnTheFly.shouldEscape(node) &&
        node.parentElement?.classList &&
        !bcl.s.translateOnTheFly.props.excludeClasses.some(function (excludedClass) {
          return node.parentElement.classList.contains(excludedClass);
        });
      return NodeFilter[nodeIsValid ? "FILTER_ACCEPT" : "FILTER_REJECT"];
    });
    do {
      const node = treeWalker.currentNode;
      if (node) {
        json["k" + bcl.s.translateOnTheFly.props.index.toString()] = { node };
        bcl.s.translateOnTheFly.props.index++;
      }
    } while (treeWalker.nextNode());
    return json;
  },
  getElementDisplayAttributes: function (element) {
    const displayAttributes = {};
    const attributeNames = element.getAttributeNames();
    for (const attrName of attributeNames) {
      if (bcl.s.translateOnTheFly.props.whiteList.includes(attrName)) {
        displayAttributes[attrName] = element.getAttribute(attrName);
      }
    }
    return displayAttributes;
  },
  treeWalkerAttributes: function () {
    const treeWalker = document.createTreeWalker(bcl.s.translateOnTheFly.props.treeWalkerRoot, NodeFilter.SHOW_ELEMENT, function (node) {
      return NodeFilter[bcl.s.translateOnTheFly.shouldEscape(node) ? "FILTER_REJECT" : "FILTER_ACCEPT"];
    });
    const json = {};
    do {
      const node = treeWalker.currentNode;
      if (node) {
        const attributes = bcl.s.translateOnTheFly.getElementDisplayAttributes(node);
        if (JSON.stringify(attributes) !== "{}") {
          json["k" + bcl.s.translateOnTheFly.props.index.toString()] = {
            node,
            attributes,
          };
          bcl.s.translateOnTheFly.props.index++;
        }
      }
    } while (treeWalker.nextNode());
    return json;
  },
  setItemsJsonArray: function (jsonEndpoint, $node, domNodesObj) {
    for (const key in domNodesObj) {
      const value = domNodesObj[key];
      let item;
      if (value.node.nodeType === Node.TEXT_NODE) {
        item = {
          key: key,
          sourceText: value.node.data.replaceAll("\n", "").trim(),
        };
        jsonEndpoint.items.push(item);
      } else if (value.attributes) {
        for (const attribute of Object.entries(value.attributes)) {
          item = {
            key: key + bcl.s.translateOnTheFly.props.attributeSeparator + attribute[0],
            sourceText: attribute[1],
          };
          jsonEndpoint.items.push(item);
        }
      }
    }
    if (!$node && document.querySelector("." + bcl.s.translateOnTheFly.props.fastBookingSelector) && bcl.c?.fastbooking?.commonProps?.calendar?.obj?.i18n) {
      jsonEndpoint = bcl.s.translateOnTheFly.setFBI8nItemsJson(jsonEndpoint);
    }
    return jsonEndpoint;
  },
  parseValue: function (value) {
    if (typeof value == "string") {
      return value;
    }
    if (value instanceof Array) {
      return value.join(",");
    }
  },
  getSessionStorageProp: function (items) {
    const data = JSON.parse(sessionStorage.getItem(bcl.s.translateOnTheFly.props.sessionStoragePropName));
    if (!data) {
      return null;
    }
    const targetLocale = data.find(function (el) {
      return el.targetLocaleId === bcl.s.translateOnTheFly.props.targetLocaleId;
    });
    if (!targetLocale) {
      return null;
    }
    let currentPage = null;
    targetLocale.pages?.forEach(function (page) {
      page = JSON.parse(page);
      if (page.url === bcl.s.translateOnTheFly.props.currentURL && page.json && page.json.length === items.length) {
        currentPage = page;
      }
    });
    return currentPage;
  },
  setSessionStorageProp: function (translatedJSON) {
    const data = JSON.parse(sessionStorage.getItem(bcl.s.translateOnTheFly.props.sessionStoragePropName)),
      pages = [];
    let array = [];
    if (!data) {
      pages.push(JSON.stringify({ url: bcl.s.translateOnTheFly.props.currentURL, json: translatedJSON }));
      array.push({ targetLocaleId: bcl.s.translateOnTheFly.props.targetLocaleId, pages });
      sessionStorage.setItem(bcl.s.translateOnTheFly.props.sessionStoragePropName, JSON.stringify(array));
      return;
    }
    array = JSON.parse(sessionStorage.getItem(bcl.s.translateOnTheFly.props.sessionStoragePropName));
    if (array) {
      let existTarget = false;
      array.forEach(function (el) {
        if (el.targetLocaleId === bcl.s.translateOnTheFly.props.targetLocaleId) {
          existTarget = true;
          if (el.pages) {
            el.pages = el.pages.filter(function (page) {
              return JSON.parse(page).url !== bcl.s.translateOnTheFly.props.currentURL;
            });
          }
          el.pages.push(JSON.stringify({ url: bcl.s.translateOnTheFly.props.currentURL, json: translatedJSON }));
        }
      });
      if (!existTarget) {
        pages.push(JSON.stringify({ url: bcl.s.translateOnTheFly.props.currentURL, json: translatedJSON }));
        array.push({ targetLocaleId: bcl.s.translateOnTheFly.props.targetLocaleId, pages });
      }
      sessionStorage.setItem(bcl.s.translateOnTheFly.props.sessionStoragePropName, JSON.stringify(array));
    }
  },
  setFBI8nItemsJson: function (jsonEndpoint) {
    for (const [key, value] of Object.entries(bcl.c.fastbooking.commonProps.calendar.obj.i18n)) {
      let item;
      if (key && value && !key.includes("-short")) {
        item = {
          key: bcl.s.translateOnTheFly.props.index + bcl.s.translateOnTheFly.props.fbi18n + key,
          sourceText: bcl.s.translateOnTheFly.parseValue(value),
        };
        bcl.s.translateOnTheFly.props.index++;
        jsonEndpoint.items.push(item);
      }
    }
    return jsonEndpoint;
  },
  getArrayByComma: function (s) {
    return s.split(/[,،]/);
  },
  setFBI18nValues: function (dataName, translationText) {
    if (typeof bcl.c.fastbooking.commonProps.calendar.obj.i18n[dataName] == "string") {
      bcl.c.fastbooking.commonProps.calendar.obj.i18n[dataName] = translationText;
    }
    if (bcl.c.fastbooking.commonProps.calendar.obj.i18n[dataName] instanceof Array) {
      const translatedArray = bcl.s.translateOnTheFly.getArrayByComma(translationText);
      if (translatedArray.length > 1) {
        bcl.c.fastbooking.commonProps.calendar.obj.i18n[dataName] = [...translatedArray];
      }
    }
  },
  handleFBTranslations: function (key, translationText) {
    if (document.querySelector("." + bcl.s.translateOnTheFly.props.fastBookingSelector) && bcl.c?.fastbooking?.commonProps?.calendar?.obj?.i18n) {
      const dataName = key.split(bcl.s.translateOnTheFly.props.fbi18n)[1];
      if (!dataName) {
        return;
      }
      if (!bcl.c.fastbooking.commonProps.calendar.obj.i18n[dataName]) {
        return;
      }
      bcl.s.translateOnTheFly.setFBI18nValues(dataName, translationText);
    }
  },
  handleDataAttributes: function (attKey, dataAttribute, translationText, domNodesObj) {
    if (dataAttribute && domNodesObj[attKey]?.node?.attributes[dataAttribute]?.value) {
      domNodesObj[attKey].node.attributes[dataAttribute].value = translationText;
    }
  },
  handleAttributes: function (key, translationText, domNodesObj) {
    const attKey = key.split(bcl.s.translateOnTheFly.props.attributeSeparator)[0];
    const attribute = key.split(bcl.s.translateOnTheFly.props.attributeSeparator)[1];
    if (!attKey || !attribute || !translationText) {
      return;
    }
    if (attribute.startsWith("data-")) {
      bcl.s.translateOnTheFly.handleDataAttributes(attKey, attribute, translationText, domNodesObj);
    } else if (domNodesObj[attKey]?.node[attribute]) {
      domNodesObj[attKey].node[attribute] = translationText;
    }
  },
  setHTMLAttributes: function () {
    document.documentElement.lang = bcl.s.translateOnTheFly.props.targetLocaleId;
    const isRtl = bcl.s.translateOnTheFly.props.rtlList.includes(bcl.s.translateOnTheFly.props.targetLocaleId);
    document.documentElement.dir = isRtl ? "rtl" : "ltr";
  },
  changeText: function (translatedJSON, domNodesObj) {
    for (const { key, translationText } of translatedJSON) {
      if (key && translationText) {
        if (key.includes(bcl.s.translateOnTheFly.props.attributeSeparator)) {
          bcl.s.translateOnTheFly.handleAttributes(key, translationText, domNodesObj);
        } else if (key.includes(bcl.s.translateOnTheFly.props.fbi18n)) {
          bcl.s.translateOnTheFly.handleFBTranslations(key, translationText);
        } else if (domNodesObj[key]?.node?.data) {
          domNodesObj[key].node.data = translationText;
        }
      }
    }
    bcl.s.translateOnTheFly.setHTMLAttributes();
  },
  handleResponse: function (responses, domNodesObj, $node) {
    let arrayTranslations = [];
    responses.forEach(function (res) {
      return (arrayTranslations = bcl.s.translateOnTheFly.getTextTranslated(res, arrayTranslations));
    });
    if (arrayTranslations && arrayTranslations.length) {
      bcl.s.translateOnTheFly.changeText(arrayTranslations, domNodesObj);
      if (!$node) {
        bcl.s.translateOnTheFly.setSessionStorageProp(arrayTranslations);
      }
    }
    bcl.s.translateOnTheFly.hideLoader($node);
  },
  translateThisText: function (jsonBody, $node, domNodesObj) {
    bcl.s.translateOnTheFly.showLoader($node);
    const url = document.querySelector("body").dataset.translateOnTheFlyTranslateUrl;
    if (!url) {
      return;
    }
    if (!jsonBody) {
      return;
    }
    const responses = [];
    const postRequestCall = function (jsonBody) {
      const remaining = jsonBody.items.slice(bcl.s.translateOnTheFly.props.maxPerRequest);
      const aux = { jsonBody, remaining };
      if (aux.remaining && aux.remaining.length) {
        jsonBody.items = jsonBody.items.slice(0, bcl.s.translateOnTheFly.props.maxPerRequest);
      }
      if (typeof jsonBody != "string") {
        jsonBody = JSON.stringify(jsonBody);
      }
      bcl.ajax.postRequest({
        data: { jsonBodyToTranslate: jsonBody },
        url: url,
        contentType: "json",
        callback: function (response, aux) {
          responses.push(response);
          if (aux?.remaining.length) {
            aux.jsonBody.items = aux.remaining;
            postRequestCall(aux.jsonBody);
          } else {
            bcl.s.translateOnTheFly.handleResponse(responses, domNodesObj, $node);
          }
        },
        itemCallback: aux,
        errorCallback: function () {
          bcl.s.translateOnTheFly.hideLoader($node);
        },
      });
    };
    postRequestCall(jsonBody);
  },
  onlyText: function (textToTranslate, sourceLocaleId, targetLocaleId) {
    const url = document.querySelector("body").dataset.translateOnTheFlyTranslateUrl;
    const jsonEndpoint = {
      sourceLocaleId: sourceLocaleId ? sourceLocaleId : bcl.s.translateOnTheFly.props.sourceLocaleId,
      targetLocaleId: targetLocaleId ? targetLocaleId : bcl.s.translateOnTheFly.props.targetLocaleId,
      items: [],
    };
    textToTranslate?.forEach(function (t, i) {
      jsonEndpoint.items.push({ key: i, sourceText: t });
    });
    if (!url) {
      return;
    }
    if (!jsonEndpoint) {
      return;
    }
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, false);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify({ jsonBodyToTranslate: JSON.stringify(jsonEndpoint) }));
    if (xhr.status === 200) {
      try {
        return JSON.parse(JSON.parse(xhr.response)[0].response);
      } catch {
        /* empty */
      }
    }
  },
  handleTranslations: function (results, arrayTranslations) {
    results?.forEach(function (result) {
      if (result.success && result.response) {
        const parsedResponse = JSON.parse(result.response);
        if (parsedResponse) {
          const translatedJSON = parsedResponse?.response?.data?.items;
          if (translatedJSON) {
            arrayTranslations = [...arrayTranslations, ...translatedJSON];
          }
        }
      }
    });
    return arrayTranslations;
  },
  getTextTranslated: function (request, auxArrayTranslations) {
    const results = JSON.parse(request.response);
    const arrayTranslations = auxArrayTranslations && auxArrayTranslations.length ? auxArrayTranslations : [];
    return bcl.s.translateOnTheFly.handleTranslations(results, arrayTranslations);
  },
  getTranslatedPageParameter: function () {
    const languageID = sessionStorage.getItem(bcl.s.translateOnTheFly.props.sessionStorageEnabledLanguageProp);
    return languageID ? `&culture=${languageID}` : "";
  },
  checkParameter: function () {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams?.get("translation")) {
      return;
    }
    const param = bcl.s.translateOnTheFly.formatLanguageCode(urlParams?.get("translation"));
    if (!param || (sessionStorage.getItem(bcl.s.translateOnTheFly.props.sessionStorageEnabledLanguageProp) && sessionStorage.getItem(bcl.s.translateOnTheFly.props.sessionStorageEnabledLanguageProp) === param)) {
      return;
    }
    const selectorLanguageOptions = document.querySelectorAll(".c-locale-form__languages-option-JS input");
    if (!selectorLanguageOptions) {
      return;
    }
    const arrayOptions = Array.from(selectorLanguageOptions);
    if (
      !arrayOptions?.length ||
      !arrayOptions.filter(function (n) {
        return n.value ? n.value === param : null;
      }).length
    ) {
      return;
    }
    sessionStorage.setItem(bcl.s.translateOnTheFly.props.sessionStorageEnabledLanguageProp, param);
  },
  formatLanguageCode: function (code) {
    if (!code) {
      return;
    }
    const codeArray = code.split("-");
    if (codeArray && codeArray.length === 2) {
      codeArray[0] = codeArray[0].toLowerCase();
      codeArray[1] = codeArray[1].toUpperCase();
      return codeArray.join("-");
    }
  },
  hasToTranslate: function () {
    return sessionStorage.getItem(this.props.sessionStorageEnabledLanguageProp) && sessionStorage.getItem(this.props.sessionStorageOriginalLanguageProp);
  },
  handleNodeTranslation: function ($node) {
    const translationEnabledLanguage = sessionStorage.getItem(bcl.s.translateOnTheFly.props.sessionStorageEnabledLanguageProp);
    if (!translationEnabledLanguage) {
      return;
    }
    const originalLanguage = sessionStorage.getItem(bcl.s.translateOnTheFly.props.sessionStorageOriginalLanguageProp);
    if (!originalLanguage) {
      return;
    }
    if (!$node) {
      return;
    }
    if ($node instanceof Array) {
      const flatten = function (arr) {
        return arr.reduce(function (flat, toFlatten) {
          return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
        }, []);
      };
      $node = flatten($node);
    }
    bcl.s.translateOnTheFly.initTranslate(originalLanguage, translationEnabledLanguage, $node);
  },
  wrapper: (function () {
    let values = [];
    let timer;
    return function (...args) {
      values.push(args);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        if (values.length) {
          bcl.s.translateOnTheFly.handleNodeTranslation(values);
          values = [];
        }
      }, 1000);
    };
  })(),
  setSessionStorageKeys: function () {
    const marketIdRegexp = /^\w{2}-\w{2}_/;
    const propNameIsSet = Boolean(this.props.sessionStoragePropName.match(marketIdRegexp));
    const enabledLanguageIsSet = Boolean(this.props.sessionStorageEnabledLanguageProp.match(marketIdRegexp));
    if (!propNameIsSet || !enabledLanguageIsSet) {
      const matches = window.location.href.match(/\/\w{2}[-_]\w{2}/g);
      if (!matches || !matches.length) {
        return;
      }
      const currentMarket = matches[0].slice(1, 6);
      if (!propNameIsSet) {
        this.props.sessionStoragePropName = `${currentMarket}_${this.props.sessionStoragePropName}`;
      }
      if (!enabledLanguageIsSet) {
        this.props.sessionStorageEnabledLanguageProp = `${currentMarket}_${this.props.sessionStorageEnabledLanguageProp}`;
      }
    }
  },
};
bcl.u.docReady(bcl.s.translateOnTheFly.init);
document.addEventListener("readystatechange", function () {
  if (document.readyState !== "complete") {
    return;
  }
  bcl.s.translateOnTheFly.setSessionStorageKeys();
  sessionStorage.setItem(bcl.s.translateOnTheFly.props.sessionStorageOriginalLanguageProp, document.documentElement.lang);
  bcl.s.translateOnTheFly.checkParameter();
  const translationEnabledLanguage = sessionStorage.getItem(bcl.s.translateOnTheFly.props.sessionStorageEnabledLanguageProp);
  if (!translationEnabledLanguage) {
    return;
  }
  setTimeout(function () {
    bcl.s.translateOnTheFly.initTranslate(document.documentElement.lang, translationEnabledLanguage);
  }, 500);
});
