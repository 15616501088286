import { dynamicImport, isPresent as isComponentPresent } from "components-utils";

const BhgComponent = function ({ selector, path, initMethod = "init", skipInit = false, belongsTo = "", customName = "", forceImport = false }) {
  return {
    isPresent: () => {
      if (forceImport) {
        return true;
      }
      return isComponentPresent(selector);
    },
    importComponent: async ({ name, root }) =>
      await import(/* webpackChunkName: "[request]" */ `./${path}.js`).then((component) =>
        dynamicImport({
          name,
          customName,
          belongsTo,
          component,
          initMethod,
          skipInit,
          root,
        }),
      ),
  };
};

const components = {
  assetVideo: BhgComponent({ selector: ".c-video", path: "asset-video", forceImport: true }),
  genericText: BhgComponent({ selector: ".cmp-text", path: "genericText" }),
};

export default components;
